<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">姓名</label>
        <el-input
          placeholder="请输入学生姓名"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchCreateStuName"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">身份证号</label>
        <el-input
          placeholder="请输入身份证号"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchCreateIdNumber"
        ></el-input>
      </div>

      <div class="condition-item">
        <label class="fn-14">学校</label>
        <el-select
          clearable
          filterable
          v-model="searchSchoolId"
          remote
          reserve-keyword
          placeholder="请输入学校名或代码检索"
          :remote-method="remoteMethod"
          :loading="loading"
          autocomplete="off"
          size="small"
        >
          <el-option
            v-for="item in schools"
            :key="item.id"
            :label="item.schoolFilterName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">手机号</label>
        <el-input
          placeholder="请输入手机号"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchCreateCell"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">下单时间</label>
        <el-date-picker
          clearable
          style="width: 180px"
          type="date"
          placeholder="下单时间"
          autocomplete="off"
          size="small"
          v-model="searchCreateDateEnd"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          clearable
          type="date"
          placeholder="选择下单时间"
          autocomplete="off"
          size="small"
          style="width: 180px"
          v-model="searchCreateDateEnd"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集员</label>
        <el-input
          placeholder="请输入采集员名字"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchCreateName"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">订单状态</label>
        <el-select
          size="small"
          clearable
          v-model="searchState"
          placeholder="全部"
          style="width: 180px"
        >
          <el-option
            v-for="item in StateList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          v-if="searchButton"
        >
          搜索
        </el-button>
        <el-button
          type="success"
          icon="el-icon-search"
          @click="handleDownload"
          :loading="DownloadLoading"
          size="small"
          v-if="downloadButton"
        >
          导出下载
        </el-button>
      </div>
    </div>
    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        :data="orderList"
        v-loading="orderLoading"
        stripe
        border
        height="100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
      >
        <template #empty>
          <p>{{ orderLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column prop="orderNo" label="原单号" width="280" fixed>
        </el-table-column>
        <el-table-column fixed prop="studentName" label="姓名" width="120" align="center">
          <template slot-scope="scope">
            <span>{{ execDecrypt(scope.row.studentName, "name") }}</span></template
          >
        </el-table-column>
        <el-table-column prop="idNumber" label="身份证号" width="280"> </el-table-column>
        <el-table-column prop="mobilePhone" label="手机号" width="180" align="center">
          <template slot-scope="scope">
            <span>{{ execDecrypt(scope.row.mobilePhone, "ph") }}</span></template
          >
        </el-table-column>

        <el-table-column prop="schoolName" label="学校" width="260" align="center">
        </el-table-column>
        <el-table-column prop="studentCode" label="学号" width="240" align="center">
        </el-table-column>

        <el-table-column
          prop="createTime"
          :formatter="columnDateFormat"
          label="下单时间"
          width="180"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="collectorName" label="采集员" width="120" align="center">
        </el-table-column>
        <el-table-column prop="status" label="订单状态" width="240" align="center">
          <template slot-scope="scope">
            <span style="color: #ff4484" v-if="scope.row.status == 0">待支付/下单</span>
            <span style="color: #ff4484" v-if="scope.row.status == 1">待处理</span>
            <span style="color: #446cf3" v-if="scope.row.status == 2">处理完成</span>
            <span style="color: red" v-if="scope.row.status == 3">支付失败</span>
            <span style="color: #666" v-if="scope.row.status == 4">取消</span>
            <span style="color: #666" v-if="scope.row.status == 5">过期取消</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="serviceDescript"
          label="服务类型"
          width="300"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="totalPrice" label="支付金额" width="120" align="center">
        </el-table-column>
        <el-table-column prop="batchName" label="操作" align="center" width="300">
          <template slot-scope="scope">
            <el-button v-if="seeButton" type="text" @click="handleSee(scope.row.id)">
              查看
            </el-button>
            <el-button
              type="text"
              @click="handleCancelorder(scope.row.id)"
              v-show="scope.row.status !== 2 && scope.row.status !== 4"
              v-if="cancelorderseeButton"
            >
              取消订单
            </el-button>
            <el-button
              type="text"
              @click="handleComplete(scope.row.id)"
              v-show="scope.row.status !== 2 && scope.row.status !== 4"
              v-if="completeButton"
            >
              确认已完成
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 取消订单 -->
    <div>
      <el-dialog
        title="取消订单"
        :visible.sync="dialogVisible"
        width="650px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form ref="CancelorderForm" label-width="170px" :rules="CancelorderRules">
          <el-form-item label="请选择取消原因：" prop="CancelorderId">
            <el-radio-group v-model="CancelorderId">
              <el-radio
                @change="handleRadioChanges(item, item.id)"
                v-for="item in CancelorderForm"
                :key="item.id"
                :value="item.name"
                :label="item.id"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button @click="handleCancel" style="margin-right: 20px">取 消 </el-button>
          <el-button type="primary" @click="handleConfirm" :loading="confirmLoading"
            >确 定
          </el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 查看订单详情 -->

    <el-dialog
      title="查看订单详情"
      :visible.sync="InfoVisible"
      width="1000px"
      append-to-body
    >
      <div style="max-height: 600px; overflow: hidden; overflow-y: auto">
        <el-form ref="orderForm" :model="orderForm" label-width="80px">
          <div class="tip">订单信息</div>
          <el-row class="rowGray">
            <el-col class="cell" :span="4">
              <label>订单号</label>
            </el-col>
            <el-col class="cell" :span="8">
              <label v-text="orderForm.orderNo"></label>
            </el-col>
            <el-col class="cell" :span="4">
              <label>支付状态</label>
            </el-col>
            <el-col class="cell" :span="8">
              <label v-text="orderForm.statusName"> </label>
            </el-col>
          </el-row>
          <el-row class="rowBlank">
            <el-col class="cell" :span="4">
              <label>付款类型</label>
            </el-col>
            <el-col class="cell" :span="8">
              <label v-text="orderForm.payTypeName"></label>
            </el-col>
            <el-col class="cell" :span="4">
              <label>商户交易号</label>
            </el-col>
            <el-col class="cell" :span="8">
              <label v-text="orderForm.payId || '-'"></label>
            </el-col>
          </el-row>

          <el-row class="rowGray">
            <el-col class="cell" :span="4">
              <label>支付时间</label>
            </el-col>
            <el-col class="cell" :span="8">
              <label>{{ formDateFormat(orderForm.paymentTime) }}</label>
            </el-col>
            <el-col class="cell" :span="4">
              <label>支付金额</label>
            </el-col>
            <el-col class="cell" :span="8">
              <label v-text="orderForm.shotPrice"></label>
            </el-col>
          </el-row>

          <el-row class="rowBlank">
            <el-col class="cell" :span="4">
              <label>交易号</label>
            </el-col>
            <el-col class="cell" :span="20" style="overflow: hidden">
              <label v-text="orderForm.payId"></label>
            </el-col>
          </el-row>
        </el-form>

        <el-form
          ref="studentForm"
          :model="studentForm"
          label-width="80px"
          style="margin-top: 15px"
        >
          <div class="tip">个人信息</div>
          <el-row class="rowGray">
            <el-col class="cell" :span="4">
              <label>姓名</label>
            </el-col>
            <el-col class="cell" :span="10">
              <label
                ><span>{{ execDecrypt(studentForm.name, "name") }}</span></label
              >
            </el-col>
            <el-col class="cell" :span="4">
              <label>证件号码</label>
            </el-col>
            <el-col class="cell" :span="6">
              {{ execDecrypt(studentForm.idNumber, "Idcard") }}
            </el-col>
          </el-row>
          <el-row class="rowBlank">
            <el-col class="cell" :span="4">
              <label>联系电话</label>
            </el-col>
            <el-col class="cell" :span="20">
              {{ execDecrypt(studentForm.mobileNumber, "ph") }}
            </el-col>
          </el-row>
        </el-form>
        <el-form
          ref="educationForm"
          :model="educationForm"
          label-width="80px"
          style="margin-top: 15px"
        >
          <div class="tip">学历信息</div>
          <div class="">
            <div class="">
              <el-row v-if="educationForm.cjmn">
                <el-col :span="20">
                  <el-row class="rowGray">
                    <el-col class="cell" :span="4">
                      <label>学校</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.schoolName"></label>
                    </el-col>
                    <el-col class="cell" :span="4">
                      <label>学校代码</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.schoolCode"></label>
                    </el-col>
                  </el-row>
                  <el-row class="rowBlank">
                    <el-col class="cell" :span="4">
                      <label>采集码</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.cjmn"></label>
                    </el-col>
                    <el-col class="cell" :span="4">
                      <label>采集号</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.sjc"></label>
                    </el-col>
                  </el-row>
                  <el-row class="rowGray">
                    <el-col class="cell" :span="4">
                      <label>学校类别</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.schoolTypeName"></label>
                    </el-col>
                    <el-col class="cell" :span="4">
                      <label>学号</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.studentCode"></label>
                    </el-col>
                  </el-row>
                  <el-row class="rowBlank">
                    <el-col class="cell" :span="4">
                      <label>学历类别</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.xllb"></label>
                    </el-col>
                    <el-col class="cell" :span="4">
                      <label>学历层次</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.educationType"></label>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="4" style="text-align: center">
                  <el-image :src="photoImg" class="photo">
                    <div slot="placeholder" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <div>
                    <el-link type="primary" v-if="isPhoto" @click="clickDown"
                      >照片下载</el-link
                    >
                  </div>
                </el-col>
              </el-row>
              <el-row v-else>
                <el-col :span="20">
                  <el-row class="rowGray">
                    <el-col class="cell" :span="4">
                      <label>学校</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.schoolName"></label>
                    </el-col>
                    <el-col class="cell" :span="4">
                      <label>学校类别</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.schoolTypeName"></label>
                    </el-col>
                  </el-row>
                  <el-row class="rowBlank">
                    <el-col class="cell" :span="4">
                      <label>校区</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.schoolArea"></label>
                    </el-col>
                    <el-col class="cell" :span="4">
                      <label>院系</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.schoolDept"></label>
                    </el-col>
                  </el-row>
                  <el-row class="rowGray">
                    <el-col class="cell" :span="4">
                      <label>专业</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.major"></label>
                    </el-col>
                    <el-col class="cell" :span="4">
                      <label>学历类别</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.educationType"></label>
                    </el-col>
                  </el-row>
                  <el-row class="rowBlank">
                    <el-col class="cell" :span="4">
                      <label>学位</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.educationType"></label>
                    </el-col>
                    <el-col class="cell" :span="4">
                      <label>学号</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.studentCode"></label>
                    </el-col>
                  </el-row>
                  <el-row class="rowGray">
                    <el-col class="cell" :span="4">
                      <label>考生号</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.examineeNumber"></label>
                    </el-col>
                    <el-col class="cell" :span="4">
                      <label>班级</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="educationForm.classes"></label>
                    </el-col>
                  </el-row>
                </el-col>

                <el-col :span="4" style="text-align: center">
                  <el-image :src="photoImg" class="photo">
                    <div slot="placeholder" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <div>
                    <el-link type="primary" v-if="isPhoto" @click="clickDown"
                      >照片下载</el-link
                    >
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="clear-float"></div>
          </div>
        </el-form>
        <el-form
          ref="orderForm"
          :model="orderForm"
          label-width="80px"
          style="margin-top: 15px"
        >
          <div class="tip">认证信息</div>
          <el-row class="rowGray">
            <el-col class="cell" :span="4">
              <label>采集类型</label>
            </el-col>
            <el-col class="cell" :span="8">
              <label v-text="orderForm.shotTypeName"></label>
            </el-col>
            <el-col class="cell" :span="4">
              <label>状态</label>
            </el-col>
            <el-col class="cell" :span="8">
              <label v-text="orderForm.statusName"></label>
            </el-col>
          </el-row>
          <el-row class="rowGray">
            <el-col class="cell" :span="4">
              <label>批次号</label>
            </el-col>
            <el-col class="cell" :span="8">
              <label v-text="orderForm.batchName"></label>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="InfoVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<style>
.tip {
  padding: 8px 16px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border-left: 5px solid #446cf3;
  margin: 5px 0;
  line-height: 20px;
}

.rowGray .cell,
.rowBlank .cell {
  padding: 0 5px;
  border: #e8e8e8 1px solid;
  height: 40px;
  line-height: 40px;
  vertical-align: bottom;
}

.rowGray .cell {
  background: #f2f2f2;
}

.divice {
  width: 1200px;
}

.divice .col-1eft {
  float: left;
  width: 900px;
  height: 160px;
}

.divice .col-right {
  float: left;
  margin-left: 5px;
  width: 200px;
  height: 150px;
}

.clear-float {
  clear: both;
}

.photo {
  width: 120px;
  height: 160px;
  position: relative;
  vertical-align: top;
}
.photo-box .item {
  text-align: center;
}
.photo-box .item .desc {
  line-height: 30px;
  text-align: center;
}
.photo-box .item .photo {
  margin-right: 0;
}
.el-radio {
  padding: 10px;
}
</style>

<script>
import {
  queryAppreciationOrders,
  finishAppreciationOrder,
  cencelAppreciationOrder,
  OrderGetIndex,
  queryCancels,
  exportAppreciationOrders,
} from "@/api/increment";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
import { getSchoolFiller } from "@/api/school";
import { dateFormat } from "@/utils/date";
import { getToken } from "@/utils/auth";
import axios from "axios";
import { queryPageButton } from "@/api/permission";

export default {
  name: "incrementOrder",
  data() {
    const validateuIsRefund = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("请选择取消原因"));
      } else {
        callback();
      }
    };
    return {
      // 列表
      orderList: [],
      schools: [],
      allSchools: [],
      searchCreateDate: null,
      searchCreateDateEnd: null,
      searchState: null,
      searchCreateStuName: "",
      searchCreateIdNumber: "",
      searchCreateCell: "",
      searchCreateName: "",
      searchSchoolId: null,
      orderLoading: false,
      loading: false,
      currentPage: 1,
      pageSize: 20,
      total: 0,
      StateList: [
        {
          label: "待处理",
          value: 1,
        },
        {
          label: "处理完成",
          value: 2,
        },
        {
          label: "取消",
          value: 4,
        },
      ],

      // 取消
      dialogVisible: false,
      confirmLoading: false,
      CancelorderForm: {},
      CancelorderId: "",
      CancelorderRules: {
        CancelorderId: [
          {
            required: true,
            trigger: "blur",
            validator: validateuIsRefund,
          },
        ],
      },
      //查看
      InfoVisible: false,
      isPhoto: false,

      photoImg: "../temp/none_img.jpg",
      uploadImg: "../temp/none_img.jpg",
      uploadImgType: 1,
      orderForm: {},
      studentForm: {},
      educationForm: {},
      SeeId: "",

      // 下载
      DownloadLoading: false,
      //权限按钮
      searchButton: false,
      downloadButton: false,
      seeButton: false,
      cancelorderseeButton: false,
      completeButton: false,
    };
  },
  created() {
    this.query();
    this.querySchools();
    this.queryButton();
  },
  methods: {
    queryButton() {
      const data = 127;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "searchButton") {
            this.searchButton = true;
          }
          if (button.code === "downloadButton") {
            this.downloadButton = true;
          }
          if (button.code === "seeButton") {
            this.seeButton = true;
          }
          if (button.code === "cancelorderseeButton") {
            this.cancelorderseeButton = true;
          }
          if (button.code === "completeButton") {
            this.completeButton = true;
          }
        }
      });
    },
    query() {
      const data = {
        stime: this.searchCreateDate,
        etime: this.searchCreateDateEnd,
        studentName: this.searchCreateStuName,
        idNumber: this.searchCreateIdNumber,
        mobileNumber: this.searchCreateCell,
        collectorName: this.searchCreateName,
        schoolName: this.searchSchoolId,
        status: this.searchState,
      };
      this.orderLoading = true;
      queryAppreciationOrders(data, this.currentPage, this.pageSize).then((resp) => {
        this.orderList = resp.data.content;
        this.total = resp.data.totalElements;
        this.orderLoading = false;
      });
    },
    querySchools() {
      getSchoolFiller().then((resp) => {
        this.allSchools = resp.data;
        this.schools = resp.data;
      });
    },

    queryReason() {
      let data = {};
      queryCancels(data).then((resp) => {
        if (resp.code == 0) {
          this.CancelorderForm = resp.data;
          this.CancelorderForm.filter((item, index) => {
            if (index == 0) {
              this.CancelorderId = item.id;
            }
          });
        } else {
          this.$notify.error({
            title: "错误",
            message: resp.message,
          });
        }
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.schools = this.allSchools.filter((item) => {
            if (typeof item != undefined) {
              return item.schoolFilterName.indexOf(query) > -1;
            }
          });
        }, 200);
      } else {
        this.schools = [];
      }
    },
    // 查看详情
    handleSee(id) {
      (this.photoImg = "../temp/none_img.jpg"),
        (this.uploadImg = "../temp/none_img.jpg"),
        (this.orderForm = []);
      this.educationForm = [];
      this.studentForm = [];

      this.InfoVisible = true;
      OrderGetIndex(id).then((resp) => {
        if (resp.code == 0) {
          this.orderForm = resp.data.orderInfo || [];
          this.educationForm = resp.data.educationalInfo || [];
          this.studentForm = resp.data.studentInfo || [];
          if (
            this.orderForm.pathCollectedPhoto == null ||
            this.orderForm.pathCollectedPhoto == ""
          ) {
            this.photoImg = "../temp/none_img.jpg?timestamp=" + new Date().getTime();
            this.isPhoto = false;
          } else {
            this.getPhoto(resp.data.orderInfo.pathCollectedPhoto);
          }
        } else {
          this.$notify.error({
            title: "错误",
            message: resp.message,
          });
        }
      });
    },

    clickDown() {
      const a = document.createElement("a");
      a.href = this.photoImg;
      console.log(a.href);
      let str =
        // this.studentForm.idNumber +
        // "-" +
        this.studentForm.name +
        "-" +
        this.educationForm.studentCode +
        "-" +
        this.educationForm.schoolName +
        "-" +
        this.educationForm.schoolId +
        ".jpg";
      a.download = str; // 下载文件的名字
      document.body.appendChild(a);
      a.click();
      //在资源下载完成后 清除 占用的缓存资源
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    },
    getPhoto(fileid) {
      const url =
        //window._config["baseUrl"] +
        "/api/csias/file/download/" + fileid + "?timestamp=" + new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            const imgContent = response.data;
            this.photoImg = window.URL.createObjectURL(imgContent);
            this.isPhoto = true;
          }
        },
        (err) => {
          this.$notify.error({
            title: "错误",
            message: "照片加载失败，未查询到照片信息",
          });
        }
      );
    }, // 取消订单
    handleCancelorder(id) {
      this.SeeId = id;
      this.dialogVisible = true;
      this.queryReason();
    },

    // 取消确定
    handleConfirm() {
      this.confirmLoading = true;
      let postData = {};
      postData.cancelId = this.CancelorderId;
      postData.id = this.SeeId;
      cencelAppreciationOrder(postData).then((resp) => {
        if (resp.code == 0) {
          this.$notify.success({
            title: "温馨提示",
            message: resp.message,
          });
        } else {
          this.$notify.error({
            title: "错误",
            message: resp.message,
          });
        }
        this.confirmLoading = false;
        this.dialogVisible = false;
        this.query();
      });
    },
    handleRadioChanges(item, id) {
      this.CancelorderId = id;
    },
    // 取消关闭

    handleCancel() {
      this.confirmLoading = false;
      this.dialogVisible = false;
    },
    handleComplete(id) {
      this.$confirm("确定要完成该订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          finishAppreciationOrder(id).then((resp) => {
            if (resp.code == 0) {
              this.$notify.success({
                title: "温馨提示",
                message: resp.message,
              });
            } else {
              this.$notify.error({
                title: "错误",
                message: resp.message,
              });
            }
            this.query();
          });
        })
        .catch(() => {
          return false;
        });
    },
    // 导出下载
    handleDownload() {
      this.DownloadLoading = true;
      const data = {
        stime: this.searchCreateDate,
        etime: this.searchCreateDateEnd,
        studentName: this.searchCreateStuName,
        idNumber: this.searchCreateIdNumber,
        mobileNumber: this.searchCreateCell,
        collectorName: this.searchCreateName,
        schoolName: this.searchSchoolId,
      };
      axios({
        method: "GET",
        url: "/api/csias/sys/appreciationOrder/exportAppreciationOrders/",
        params: {
          page: data,
        },
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
        },
        responseType: "blob",
        timeout: 1200000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            let blob = new Blob([response.data], {
              type: "application/octet-stream",
            });

            let fileName = Date.parse(new Date()) + ".xlsx";
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
            }
            this.$notify.success({
              title: "成功",
              message: "下载文件成功！",
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: "下载失败，未查询到数据",
            });
          }
          this.DownloadLoading = false;
        },
        (err) => {
          this.DownloadLoading = false;
          this.$notify.error({
            title: "错误",
            message: "下载失败，未查询到数据",
          });
        }
      );
    },

    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },

    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
};
</script>

<style></style>
