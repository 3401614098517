import request from "@/utils/request";

export function querySchool(page, size, data) {
  return request({
    url: "/sys/schoolInfo/querySchool?page=" + page + "&size=" + size,
    method: "post",
    data,
  });
}

export function addSchool(data) {
  return request({
    url: "/sys/schoolInfo/addSchool",
    method: "post",
    data,
  });
}

export function getSchool(account) {
  return request({
    url: "/sys/schoolInfo/getSchool/" + account,
    method: "get",
  });
}

export function updateSchool(data) {
  return request({
    url: "/sys/schoolInfo/updateSchool",
    method: "post",
    data,
  });
}

export function deleteSchool(data) {
  return request({
    url: "/sys/schoolInfo/deleteSchool",
    method: "post",
    data,
  });
}


export function getSchoolFiller() {
  return request({
    url: "/sys/schoolInfo/getSchoolFiller",
    method: "get",
  });
}
//查询学校授权信息
export function checkSchoolEduType() {
  return request({
    url: "/sys/sysParam/getAuthorizeParam" ,
    method: "get",
  });
}
//保存学校授权信息
export function postSchoolEduType(data) {
  return request({
    url: "/sys/sysParam/updateAuthorizeParam",
    method: "post",
    data,
  });
}


/**
 * 异步上传学历
 */
export function importEduTypeAsyn(data) {
  return request({
    url: `/sys/schoolInfo/importEduTypeAsyn`,
    method: "post",
    data,
  });
}