import request from "@/utils/request";
import { getToken } from "@/utils/auth";

// 新增服务列表查询
export function getqueryServices(data = {}, page, size) {
  return request({
    url: "/sys/orderServices/queryServices?page=" + page + "&size=" + size,
    method: "post",
    data,
  });
}

// 新增服务类型
export function addService(data) {
  return request({
    url: "/sys/orderServices/addService",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 修改服务类型
export function getIndex(data) {
  return request({
    url: "/sys/orderServices/getIndex/" + data,
    method: "get",
  });
}

// 修改保存服务类型
export function updateService(data) {
  return request({
    url: "/sys/orderServices/updateService",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 删除服务类型
export function deleteService(id) {
  return request({
    url: "/sys/orderServices/deleteService/" + id,
    method: "post",
    id,
  });
}

// 文件上传
export function uploadFile(data) {
  return request({
    url: "/file/uploadFile",
    method: "post",
    data,
  });
}

// 修改服务状态
export function updateState(data) {
  let id = data.id;
  return request({
    url: "/sys/orderServices/update/" + id,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 取消原因列表
export function queryCancels(data) {
  return request({
    url: "/sys/cancelInfo/queryCancels",
    method: "get",
    data,
  });
}

// 添加取消原因
export function addCancel(data={}) {
  return request({
    url: "/sys/cancelInfo/addCancel",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 删除取消原因
export function deleteCancel(id) {
  return request({
    url: "/sys/cancelInfo/deleteCancel/" + id,
    method: "post",
    id,
  });
}

// 采集员列表查询
export function queryCollectors(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/collectors/queryCollectors?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}

// 添加采集员
export function addCollector(data) {
  return request({
    url: "/sys/collectors/addCollector",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 修改采集员状态
export function updateCollectors(data) {
  let id = data.id;
  return request({
    url: "/sys/collectors/update/" + id,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 订单管理列表查询
export function queryAppreciationOrders(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/appreciationOrder/queryAppreciationOrders?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 订单列表查看
export function OrderGetIndex(id) {
  return request({
    url: "/sys/appreciationOrder/getIndex/" + id,
    method: "get",
    id,
  });
}

// 取消增值订单
export function cencelAppreciationOrder(data) {
  return request({
    url: "/sys/appreciationOrder/cencelAppreciationOrder",
    method: "post",
    data,
  });
}

// 确认已完成
export function finishAppreciationOrder(id) {
  return request({
    url: "/sys/appreciationOrder/finishAppreciationOrder" + id,
    method: "post",
    id,
  });
}
// 订单列表导出
export function exportAppreciationOrders(data) {
  debugger
  return request({
    url: "/sys/appreciationOrder/exportAppreciationOrders",
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json",
    },
    responseType: "blob",
    timeout: 1200000,
    data,
  });
}
